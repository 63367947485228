import React, { useEffect } from 'react';
import './contents.scss';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

export default function Aboutus() {

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };

    }, [])

    const content = useSelector(state => state.home.contents?.aboutUs)

    return (
        <div className='content-holder'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Your Destination for Healthy Recipes and Culinary Inspiration</title>
                <meta name="description" content="Learn about Mashup Now! We provide healthy recipes for dinner, weight loss diets, vegan dishes, and quick bites for every taste." />
                <link rel="canonical" href="https://www.mashupnow.com/about-us" />
            </Helmet>
            <div className='banner-image' >
                <img src='./images/background-images/aboutus.jpg' alt='' />
                <h5>About us</h5>
            </div>
            <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    )
}

