import axios from "axios";
import { v4 as uuid } from 'uuid';
import { getIPAddressWithCity } from "./miscellneous";



const newUuid = ((window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/') ? uuid() : localStorage.getItem('uid'));
(localStorage.getItem('uid') == null && localStorage.setItem('uid', newUuid));

const IpDetails = await getIPAddressWithCity();


const AuthStr = "Bearer " + localStorage.getItem("userAuthToken");
const headers = {
    Authorization: localStorage.getItem("userAuthToken") ? AuthStr : '',
    'device-uuid': (localStorage.getItem('uid') !== null ? localStorage.getItem('uid') : newUuid),
    'device-type': 'web',
    'device-token': '',
    'app-name': 'website',
    'ip-address': IpDetails?.ip,
    "city": IpDetails?.city ? IpDetails?.city : '',
    "country": IpDetails?.country ? IpDetails?.country : ''

}



function getApiCall(url) {
    return axios
        .get(process.env.REACT_APP_API_KEY + url, {
            headers: headers,
        })
        .then((data) => {
            return data;
        });
}

function postApiCall(url, body) {
    return axios
        .post(process.env.REACT_APP_API_KEY + url, body, {
            headers: headers,
        })
        .then((data) => {
            return data;
        });
}

function putApiCall(url, body) {
    return axios
        .put(process.env.REACT_APP_API_KEY + url, body, {
            headers: headers,
        })
        .then((data) => {
            return data;
        });
}

function deleteApiCall(url) {
    return axios
        .delete(process.env.REACT_APP_API_KEY + url, {
            headers: headers,
        })
        .then((data) => {
            return data;
        });
}


export { getApiCall, postApiCall, putApiCall, deleteApiCall };
