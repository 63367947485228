import axios from "axios";

export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/right-arrow.png" width='25px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/left-arrow.png" width='25px' alt='' />
        </div>
    );
}

export const getIPAddress = async () => {
    try {
        const ipAddress = await axios.get('https://api.ipify.org/?format=json');
        return ipAddress.data.ip;
    }
    catch (error) {
        console.error("Error:", error.message);
    }
}

export const getIPAddressWithCity = async () => {
    const apiKey = process.env.REACT_APP_GETIP_API_KEY;
    const getIpUrl = `https://api.ipify.org/?format=json`;
    const lookupUrl = `https://api.whatismyip.com/ip-address-lookup.php?key=${apiKey}&input=`;

    try {
        const ipResponse = await axios.get(getIpUrl);
        const ip = ipResponse.data.ip; // The response data is plain text for the first API
        console.log(ip)
        const response = await axios.get(`${lookupUrl}${ip}&output=json`);
        const data = {
            city: response.data.ip_address_lookup[0]?.city,
            country: response.data.ip_address_lookup[0]?.country,
            ip: ip
        }
        return data
    } catch (error) {
        console.error("Error:", error.message);
    }
}

export const extractNamesFromUrls = (urls) => {
    return urls?.map(url => {
        // Extract the file name with extension
        const fileNameWithExtension = url.split('/').pop();
        // Remove the extension to get just the name
        return fileNameWithExtension.split('.').slice(0, -1).join('.');
    });
}

export function isExpired(dateToCheck) {
    const today = new Date();
    const date = new Date(dateToCheck);

    // Set the time of both dates to the start of the day for an accurate comparison
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    // Check if the given date is in the past
    return date < today;
}